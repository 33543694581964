import { styled, css } from "styled-components";
import theme from "styled-theming";

import Form from "components/Form";
import { Container as GridContainer } from "style/components/Page";
import { styleBodyL, styleHeadingXL } from "style/components/Typography";
import { GUTTER } from "style/grid";
import { topPadOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    position: relative;

    ${MQ.FROM_M} {
        column-gap: ${GUTTER.XL};
        display: grid;
        grid-auto-rows: auto;
        grid-template-areas: "text form";
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const MultiStepWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const MultiStepContent = styled(GridContainer)`
    padding-bottom: 0;
    position: relative;
`;

export const Content = styled.div.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    ${MQ.FROM_M} {
        align-self: flex-start;
        grid-area: text;
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 6",
            Right: "col-start 7 / span 6",
        })};
        position: sticky;
        text-align: ${theme("displayOption", {
            Left: "left",
            Right: "right",
        })};
        top: 4.375rem;
        ${topPadOffset};
    }
`;

export const Heading = styled.h2`
    ${styleHeadingXL};
    margin-bottom: 1.75rem;
    margin-top: 0;
`;

export const Introduction = styled.div`
    ${styleBodyL};
    margin-top: 0;
`;

export const StyledForm = styled(Form)`
    justify-content: flex-start;
    ${theme("isMultiStepForm", {
        false: css`
            ${MQ.FROM_M} {
                grid-area: form;
                grid-column: ${theme("displayOption", {
                    Left: "col-start 7 / span 6",
                    Right: "col-start 1 / span 6",
                })};
            }

            ${MQ.FROM_XL} {
                grid-column: ${theme("displayOption", {
                    Left: "col-start 7 / span 5",
                    Right: "col-start 2 / span 5",
                })};
            }
        `,
    })}
`;
